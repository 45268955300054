import restaurant from "./images/butoias.jpg.crdownload";
import img_card from "./images/8.png";
import imgheaderrestaurant from "./images/14.jpg";
import imgheader from "./images/img6.jpg";
import imgheaderm from "./images/img6.jpg";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/img4.jpg";
import imgheadermiinimb from "./images/img4.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Ivan",
        mireasa: "Veronica",
        data: "5 August 2023",
        data_confirmare: "20 iulie 2023",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "basocivanveronica@gmail.com", 
       tel: "+37360989659",
       phone: "tel:+37360989659",
       viber: "viber://chat?number=%2B37360989659",
       whatsapp: "https://wa.me/+37360989659",
       messenger: "https://www.messenger.com/t/veronica.vrabie.90",
       tel1: "+37368355031",
       phone1: "tel:+XXX",
       viber1: "viber://chat?number=%2BXX",
       whatsapp1: "https://wa.me/+XXX",
       messenger1: "https://www.messenger.com/t/100008466997487",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Butoiaș",
            data: "5 august 2023, sâmbătă, ora 17:00",
            adress: "Strada Calea Ieşilor 71, Chișinău",
            harta: "https://goo.gl/maps/1CUgw7P82BAZDtGF9",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10874.621825097716!2d28.7789674!3d47.0469913!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8b2cc3aaa85625ec!2zQnV0b2lhyJk!5e0!3m2!1sro!2s!4v1671088786468!5m2!1sro!2s"
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care vom celebra căsătoria nostră, vrem să fim inconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "NAȘII",
            nasii_nume: "Demian Veceslav & Olesea",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Dragostea constă în dorința de a da ceea ce este al tău altuia și de a simți fericirea acestuia ca și cum ar fi a ta. \" – Emanuel Swdenborg",
        }
    ],

}

export default data;

import React, { Component } from "react";

import music from "../images/music.png"

import muz from "../video/vals.mp3"


export default class Blog extends Component {
  render() {
    return (

      <div className="container blog-container mt-4">
        <div className="row main-row ">
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="blog-img text-center">
              <img className="img-fluid" src={this.props.img} />
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="blog-title mt-lg-5 mt-md-2 mt-sm-1">
            <span className="text-center">
    <img className="music animate__animated animate__pulse animate__delay-4s animate__infinite" src={music}/>
    <audio src={muz} controls autoPlay loop ></audio>
</span>
              <h5 className="text-center">
                {this.props.title1}
              </h5>
              <h2 className="text-center p-2">
                {this.props.title2}
              </h2>
              <h4 className="text-center pb-5">
                {this.props.message}
              </h4>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

